<template>
    <v-card>
        <v-card-title>
            <h3>Help</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search..."
                clearable
                single-line
                hide-details
            />
        </v-card-title>
    </v-card>
</template>
<script>
export default {
    name: 'help',
    data: () => ({
        keywords: '',
    }),
}
</script>
